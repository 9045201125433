<template>
  <v-card
    v-if="navigation"
    :to="routePath"
    class="d-flex flex-column justify-center align-center pt-5 pb-4 h-100"
  >
    <v-icon color="primary" large class="mb-5">{{ icon }}</v-icon>
    <v-card-title
      class="py-0 text-body-2 font-weight-bold default--text text-center"
    >
      {{ title }}
    </v-card-title>
  </v-card>
  <v-card
    v-else-if="title == 'Free WiFi'"
    @click.stop="checkPdvNetwork"
    class="d-flex flex-column justify-center align-center pt-5 pb-4 h-100"
  >
    <v-icon color="primary" large class="mb-5">{{ icon }}</v-icon>
    <v-card-title
      class="py-0 text-body-2 font-weight-bold default--text text-center"
    >
      {{ title }}
    </v-card-title>
  </v-card>
  <v-card
    v-else
    @click.stop="doLogout"
    class="d-flex flex-column justify-center align-center pt-5 pb-4 h-100"
  >
    <v-icon color="primary" large class="mb-5">{{ icon }}</v-icon>
    <v-card-title
      class="py-0 text-body-2 font-weight-bold default--text text-center"
    >
      {{ title }}
    </v-card-title>
  </v-card>
</template>
<style scoped lang="scss">
.h-100 {
  height: 100%;
}
.v-card__title {
  word-break: break-word;
}
</style>
<script>
import { mapActions } from "vuex";
import PdvWifiService from "~/service/PdvWifiService";

export default {
  name: "DashboardCard",
  props: {
    title: { type: String, required: true },
    icon: { type: String, required: true },
    routePath: { type: String, required: true },
    navigation: { type: Boolean, required: true },
    logoutFunc: { type: Boolean, required: false }
  },
  methods: {
    ...mapActions({
      logout: "cart/doLogout"
    }),
    async doLogout() {
      await this.logout();
      this.$router.push({
        name: "Home",
        path: "/"
      });
    },
    async checkPdvNetwork() {
      let configuration = await PdvWifiService.getConfiguration();
      PdvWifiService.checkForPdvNetwork(configuration.data.LOCAL_STORAGE);
    }
  }
};
</script>
