import axios from "~/plugins/axios";
import Popup from "@/components/Popup";

export default {
  checkForPdvNetwork(storedConfiguration) {
    if (
      storedConfiguration.wifiNetworkList &&
      storedConfiguration.wifiNetworkList.length > 0 &&
      window.cordova &&
      // eslint-disable-next-line no-undef
      WifiWizard2
    ) {
      if (
        window.cordova &&
        window.cordova.platformId.toLowerCase() === "android"
      ) {
        this.searchPdvNetwork(storedConfiguration);
      } else if (
        window.cordova &&
        window.cordova.platformId.toLowerCase() === "ios"
      ) {
        var pdvNetwork = storedConfiguration.wifiNetworkList[0];
        // eslint-disable-next-line no-undef
        WifiWizard2.iOSConnectNetwork(
          pdvNetwork.ssid,
          pdvNetwork.password
        ).then(
          function() {
            this.wifiConnectionSuccess();
          },
          function() {
            //wifiConnectionError();
          }
        );
      }
    } else {
      this.wifiNetworkUnavailable();
    }
  },
  getConfiguration() {
    return axios.get("/ebsn/app-configurations/config.json", {}).then(data => {
      return data.data;
    });
  },
  searchPdvNetwork(storedConfiguration) {
    var pdvNetwork = null;
    var _this = this;
    // eslint-disable-next-line no-undef
    WifiWizard2.scan().then(
      function(networkList) {
        for (
          var i = 0;
          i < storedConfiguration.wifiNetworkList.length && !pdvNetwork;
          i++
        ) {
          for (var j = 0; j < networkList.length && !pdvNetwork; j++) {
            if (
              storedConfiguration.wifiNetworkList[i].ssid == networkList[j].SSID
            ) {
              pdvNetwork = storedConfiguration.wifiNetworkList[i];
            }
          }
        }
        if (pdvNetwork) {
          _this.connectToPdvNetwork(pdvNetwork);
        } else {
          _this.wifiNetworkUnavailable();
        }
      },
      function() {
        _this.wifiNetworkUnavailable();
      }
    );
  },
  async wifiNetworkUnavailable() {
    let popupConfig = {};
    popupConfig.TITLE = "Connessione Free Wifi";
    popupConfig.DESCRIPTION =
      "Attenzione: nel luogo in cui ti trovi non sono state rilevate reti wifi abilitate al servizio Free Wifi.<br/>" +
      "In caso di problemi, verifica di aver acceso il wifi e di aver abilitato i permessi di localizzazione.";
    await global.vm.$dialog.show(Popup, {
      popup: popupConfig
    });
  },
  connectToPdvNetwork(pdvNetwork) {
    // $ionicLoading.hide();
    global.vm.$dialog.confirm({
      text:
        "Vuoi connetterti alla rete wifi del punto vendita e usare la nostra connessione a internet?" +
        "<br/> Potrai connetterti in qualunque momento tramite l'apposita voce nel menu",
      title: "Connessione Free Wifi",
      icon: false,
      actions: {
        false: "No",
        true: {
          text: "Sì",
          handle: () => {
            // eslint-disable-next-line no-undef
            WifiWizard2.connect(
              pdvNetwork.ssid,
              false,
              pdvNetwork.password,
              pdvNetwork.algorithm
            ).then(
              function() {
                this.wifiConnectionSuccess();
              },
              function() {
                this.wifiConnectionError();
              }
            );
            return;
          }
        }
      }
    });
  },
  async wifiConnectionSuccess() {
    let popupConfig = {};
    popupConfig.TITLE = "Connessione Free Wifi";
    popupConfig.DESCRIPTION =
      "<div>" +
      "<strong>ACCESSO RIUSCITO</strong><br/>" +
      "Ora puoi navigare su internet" +
      "</div>";
    await global.vm.$dialog.show(Popup, {
      popup: popupConfig
    });
  },
  async wifiConnectionError() {
    let popupConfig = {};
    popupConfig.TITLE = "Connessione Free Wifi";
    popupConfig.DESCRIPTION =
      "<div></strong>ACCESSO NON RIUSCITO</strong><br/>" +
      "Riprova tra un momento" +
      "</div>";
    await global.vm.$dialog.show(Popup, {
      popup: popupConfig
    });
  }
};
